"use client";
import React from "react";
import type { IRole } from "@aspen/libs";
import {
  APP_DOWNLOAD_ADDRESS,
  checkPathName,
  DOMAIN_PREFIX_PARTNER,
  DOMAIN_PREFIX_SERVICE,
  GA_EVENT_NAME,
  i18nUtil,
  isClient,
  isMobile,
  isPad,
  reportEvent,
  SUPPORT_APP_SHARE_PATH,
  HEADER_CUSTOMER_ROLE,
  HEADER_SUB_ROLE,
  USER_ROLE,
  USER_AUTH
} from "@aspen/libs";
import { Affix, Dropdown, Modal } from "antd";
import { QuestionCircleOutlined, CustomerServiceOutlined } from "@ant-design/icons";
import Image from "next/image";
import type { IInjectProps } from "@aspen/model";
import { BRAND } from "@aspen/model";
import { AgencyTipsArea, Header } from "@aspen/widgets";
import { Footer } from "@aspen/ui";
import styles from "@aspen/theme/WithLayout.module.less";

const gotoGoogle = require("@aspen/assets/images/gotoGoogle.png");

const defaultProps = {};

type IProps = {
  children: React.ReactNode;
  routeConfig: IRole;
} & IInjectProps &
  Partial<typeof defaultProps>;

type IState = {
  showModal: boolean;
};

export default class WithLayout extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showModal: true
    };
    this.handleOk = this.handleOk.bind(this);
  }

  handleOk(isCustomerRole: boolean) {
    this.setState({ showModal: false });
    if (isCustomerRole) {
      reportEvent({
        moduleName: GA_EVENT_NAME.common.accessApp
      });
      window.location.href = APP_DOWNLOAD_ADDRESS;
    }
  }

  renderModal() {
    const intl = i18nUtil.t();
    //   代理商和帮客不显示app下载入口
    const isCustomerRole =
      isClient &&
      !(
        window.location.hostname.includes(DOMAIN_PREFIX_PARTNER) ||
        window.location.hostname.includes(DOMAIN_PREFIX_SERVICE)
      );

    return (
      <Modal
        wrapClassName="accessAppModal"
        width="70%"
        open={this.state.showModal}
        okText={isCustomerRole ? intl["access.via.app"] : intl["button.ok"]}
        onOk={() => this.handleOk(isCustomerRole)}
        onCancel={() => {
          this.setState({ showModal: false });
        }}
        centered>
        <div style={{ margin: "30px 0 20px 0" }}>
          <p style={{ fontSize: 30 }}>
            {isClient && isCustomerRole
              ? i18nUtil.formatMessage({ id: "jump.to.app.tip" }, { BRAND })
              : i18nUtil.formatMessage({ id: "app.browser.access.tip" }, { BRAND })}
          </p>
        </div>
      </Modal>
    );
  }
  renderHelpCenter = () => {
    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
          className={styles.mainColorStyle}>
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLScTitYq7cQ1TqWkjg9b_UVkJVCFM5pDyEu5eTWX7IVtdkwuZA/viewform"
            target="_blank"
            rel="noopener">
            <QuestionCircleOutlined className={styles.mainColorStyle} style={{ fontSize: 26 }} />
          </a>
          <a
            href="https://support.aspendigital.co/hc/en-hk?token=logined"
            target="_blank"
            rel="noopener">
            <CustomerServiceOutlined
              className={styles.mainColorStyle}
              style={{ fontSize: 26, margin: "12px 0px" }}
            />
          </a>
        </div>
      </>
    );
  };

  render() {
    const pathname = checkPathName(this.props.router?.pathname);
    const { routeConfig } = this.props;
    const _showHeader =
      routeConfig?.[pathname]?.needAuthenticated || routeConfig?.[pathname]?.showHeader || false;

    //  隐藏header和footer 并且不提示跳转app的弹框 （1.资讯news详情页兼容APP；2.kyc页面）, todo, 在appOnly系统传递参数，这里不做逻辑处理 @张琦 @于衍
    const showNewsDetailAPP =
      isMobile() &&
      !isPad() &&
      SUPPORT_APP_SHARE_PATH?.some((path) => location.pathname.includes(path));

    const user_auth = isClient && window.localStorage.getItem(USER_AUTH);
    const headerProps = user_auth == USER_ROLE.ROLE_SUB ? HEADER_SUB_ROLE : HEADER_CUSTOMER_ROLE;

    return (
      <>
        {(isMobile() || isPad()) &&
          !SUPPORT_APP_SHARE_PATH?.some((path) => location.pathname.includes(path)) &&
          this.renderModal()}
        {_showHeader && !showNewsDetailAPP ? (
          <>
            <Header router={this.props.router} routeConfig={routeConfig} {...headerProps} />
            <AgencyTipsArea />
            <div className="App">
              {this.props.children}
              <Affix style={{ position: "fixed", bottom: 20, right: 20, zIndex: 9 }}>
                <Dropdown
                  placement="top"
                  arrow={{ pointAtCenter: true }}
                  dropdownRender={this.renderHelpCenter}>
                  <Image unoptimized src={gotoGoogle} width={40} height={40} alt="" />
                </Dropdown>
              </Affix>
            </div>
          </>
        ) : (
          <>{this.props?.children}</>
        )}
        {/* 这行垃圾代码别删,因为在服务端渲染之后,返回到客户端,判断是否是mobile之后不再重新渲染 */}
        <div style={{ display: "none" }}>{`${showNewsDetailAPP}`}</div>
        {showNewsDetailAPP || <Footer />}
      </>
    );
  }
}
